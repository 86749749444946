<template>
  <div>
    <div class="box" v-show="mines">
      <img class="cha" @click="close" src="../assets/img/ding/icon-close.png" alt="" />
      <div class="box-main">
        <div class="main-nav">
          <h1>帐号查看</h1>
        </div>
        <!-- 账号 -->
        <div class="accounts">
          <div class="xing">*</div>
          <label class="accounts-label" for="">帐号&#12288;&#12288;</label>
          <input class="accounts-input" type="text" v-model="username"/>
        </div>
        <!-- 联系人 -->
          <div class="plate">
          <div class="xing">*</div>
          <label class="accounts-label" for="">联系人&#12288;</label>
          <input class="accounts-input" type="text" v-model="name" placeholder="车主姓名" id="newname" />
        </div>
        <!-- 私人号码 -->
        <div class="plate">
          <div class="xing">*</div>
          <label class="accounts-label" for="">私人号码</label>
          <input class="accounts-input" type="text" v-model="tel1" placeholder="请输入私人号码"  />
        </div>
        <!-- 紧急号码 -->
        <div class="plate">
          <div class="xing">*</div>
          <label class="accounts-label" for="">紧急号码</label>
          <input class="accounts-input" type="text" v-model="tel2" placeholder="请输入紧急号码" />
        </div>
        <!-- <div class="verify">
          <div class="xing">*</div>
          <label class="accounts-label" for="">联系方式</label>
          <input class="verify-name" type="text" v-model="name" id="newname"/>
          <input class="verify-phone" type="text" v-model="tel" id="newtel"/>
        </div> -->

        <!-- 车牌号 -->
        <div class="plate">
          <div class="xing">*</div>
          <label class="accounts-label" for="">车牌号&#12288;</label>
          <input class="plate-input" type="text" v-model="carNumber" id="newcarNumber" v-if="editcarnumber == false"  readonly/>
          <input class="plate-input" type="text" v-model="carNumber" id="newcarNumber" v-else />
        </div>
        <!-- 服务车型 -->
        <div class="plate">
          <div class="xing">*</div>
          <label class="accounts-label" for="">服务车型</label>
          <el-select filterable  v-model="carstyle" placeholder="选择车型"  @change="changecarstyle">
             <el-option v-for="item in styleList" :key="item.name" :label="item.name" :value="item.name">
            </el-option>                          
          </el-select>
        </div>
        <div class="item1" v-if="hasOp">
          <div class="xing">*</div>
          <label class="accounts-label" for="">技师类型</label>
          <div class="item-main">
            <el-radio
            v-model="isOp"
            label="0"
            size="large"
            >普通技师</el-radio
          >
          <el-radio
            v-model="isOp"
            label="1"
            size="large"

            >外协技师</el-radio
          >
          </div>
        </div>
        <!-- 服务项目 -->
        <div class="item">
          <div class="xing">*</div>
          <label class="accounts-label" for="">服务项目</label>
          <div class="item-main">
            <el-checkbox-group v-model="checkboxGroup1" size="large" @change="changecheck">
              <el-checkbox-button
                v-for="city in cities"
                :key="city.id"
                :label="city.id"
              >
                {{ city.name }}
              </el-checkbox-button>
            </el-checkbox-group>
          </div>
        </div>

  

        <!-- 终端 -->
        <div class="terminal" v-if='false'>
          <div class="xing"></div>
          <label class="accounts-label" for="">终端&#12288;&#12288;</label>
          <button class="bang">点击绑定</button>
        </div>
        <div class="btn" v-if="!getlook">
          <input class="plate-input" type="hidden" v-model="type" id="newtype"/>
          <!-- <button class="set"    @click="commit(detail.uid)">保存</button> -->
          <el-button class="set" :plain="true" @click="commit(detail.uid)">保存</el-button>

          <button class="cancel" @click="close" >取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import * as api from '../api/car.js';

const checkboxGroup1 = [];
const cities = [];
export default {
  props:["ismine","uid","hasOp"],
  data() {
    return {
      checkboxGroup1,
      cities,
      mines:this.ismine,
      detail:{},
      username:'',
      name:'',
      tel:'',
      tel1:'',
      tel2:'',
      carNumber:'',
      type:'',
      carstyle:'',
      isOp:'0',
      styleList:[],
      showstyle:false,
      editcarnumber:true,
    };
  },
  async create(){
  },
  methods: {
      close() {
          this.mines = false;
      },
      changecheck(e)
      {
        this.showstyle = this.checkboxGroup1.includes('1');
      },
      changecarstyle()
      {

      },
     async commit(uid){
          if(this.carstyle == '' || this.carstyle == undefined)
          {             
              this.$message.warning('请选择服务车型');
              return;
          }
          if(this.name == '')
          {
              this.$message.warning('请输入姓名');
              return;
          }
          if(this.carNumber == '')
          {
              this.$message.warning('请输入车牌');
              return;
          }
          if(this.tel == '')
          {
             this.tel = this.detail.username;
          }
          if(this.checkboxGroup1.length == 0)
          {
             this.$message.warning('请勾选服务项目');
              return;
          }

          if(this.username == '')
          {
            this.$message.warning('请输入正确的账号');
            return;            
          }
          let username = this.username;
          let newrescue = this.checkboxGroup1;
          let post_name = this.name;
          let post_tel = this.tel;
          let post_carNumber = this.carNumber;
          let post_type = this.type;
          let post_carstyle = this.carstyle;

          let post_rescue = '';
          if(newrescue.length > 0){
            for(var i = 0;i<newrescue.length;i++)
            {
              if(i == 0){
                if(newrescue[i] != 2)
                  post_rescue += newrescue[i];
              }else{
                if(newrescue[i] != 2)
                  post_rescue += ','+newrescue[i];
              }
            }
          }

          let res = await api.carUpdate(uid,post_name,post_tel,post_carNumber,post_rescue,post_type,post_carstyle,this.tel1,this.tel2,this.isOp,username);

          if(res.code == 1)
          {
            this.$message.success('成功')
            this.$emit('carsx',1);
            this.close();
          }else{
            // alert(res.message);
            this.$message.warning(res.message)
          }
      }
  },
  computed: {
    getlook() {
      return this.$store.state.lookonly;
    }
  },
  watch:{
    ismine(newval){
      //console.log('---',newval);
      this.mines = newval
    },
    async uid(newVal){
      let res = await api.carDetail(newVal);
      //console.log(res);
      this.checkboxGroup1 = res.data.rescueType;
      this.detail = res.data;
      this.username = this.detail.username;
      this.name = this.detail.name;
      this.tel = this.detail.tel;
      this.tel1 = this.detail.tel1;
      this.tel2 = this.detail.tel2;
      this.carNumber = this.detail.carNumber;
      this.type = this.detail.type;
      this.cities =res.data.rescueTypeList;
      this.carstyle = this.detail.rescue_car_style;
      this.styleList = res.data.stylelist;
      this.isOp = res.data.is_op.toString();
      this.showstyle = this.checkboxGroup1.includes('1');
      if(this.detail.terminal == 1)
      {
        this.editcarnumber = false;
      }else{
         this.editcarnumber = true;
      }
     // console.log('*****',this.cities);
    }
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 640px;
  height: 940px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 22;
  // background-color: pink;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 600px;
  height: 940px;
  background: #fff;
  margin-left: 40px;
  margin-top: -21px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 160px;
  height: 26px;
  padding-top: 21px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.accounts,
.verify,
.plate,
.terminal {
  width: 430px;
  height: 40px;
  margin-bottom: 12px;
  //   background-color: pink;
}
.item,.item1 {
  margin-left: 41px;
  display: flex;
}
.item1{
  margin-bottom: 10px;
}
.item-label {
  float: left;
  height: 19px;
  font-size: 14px;
  margin-right: 5px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
  color: #2a3346;
  opacity: 1;
}
.item-main {
  width: 240px;
}
.el-checkbox-button {
  margin-right: 8px;
  margin-bottom: 12px;
  width: 64px;
  height: 34px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
}
.accounts {
  margin-left: 41px;
  margin-top: 30px;
}
.verify {
  margin-left: 41px;
}
.plate {
  margin-left: 41px;
}
.terminal{
    margin-left: 41px;
    margin-top: 10px;
}
.xing {
  float: left;
  width: 6px;
  height: 19px;
  font-size: 14px;
  margin-top: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #ff505a;
  opacity: 1;
}
.accounts-label,
.verify-label,
.plate-label,
.plate1-label,
.terminal-label {
  width: 70px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
  color: #2a3346;
  opacity: 1;
  margin-right: 20px;
  display: inline-block;
}
// .terminal-label {
//   margin-right: 48px;
// }
button {
  width: 289px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
}
.accounts-input,
.plate-input,
.plate1-input {
  width: 275px;
  height: 32px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}
.accounts-input:hover{
  border: 1px solid #c0c4cc;
}
.plate-input:hover{
  border: 1px solid #c0c4cc;
}
input[type=text]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}

.verify-name {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
  padding-left: 12px;
  // margin-left: 20px;
  box-sizing: border-box;
}
.verify-name:hover{
  border: 1px solid #c0c4cc;
}
.verify-phone:hover{
  border: 1px solid #c0c4cc;
}
.verify-phone {
  width: 163px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  margin-left: 10px;
  padding-left: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 140px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  vertical-align: top;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}

.item-main /deep/ .el-checkbox-button{
  border: 0;
  border-radius: 2px;
}
.item-main /deep/ .el-checkbox-button--large .el-checkbox-button__inner{
  border-left: 1px solid #dcdfe6;
  border-radius: 2px;
}

.bang{
  height: 32px;
  border-radius: 4px;
}

.plate /deep/ .el-input__inner {
  width: 275px;
}
</style>
