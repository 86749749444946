<template>
  <div>
    <div class="box">
      <div class="nav-box">
        <div class="mt-4">
          <el-select
            filterable
            class="input-unit"
            v-model="techid"
            @change="search"
            placeholder="全部技师"
          >
            <el-option
              v-for="item in allList"
              :key="item.uid"
              :value="item.uid"
              :label="item.nickname"
            >
            </el-option>
          </el-select>

          <!-- 终端 -->
          <div class="zhong">
            <el-select
              class="input-unit"
              v-model="duan"
              @change="search1"
              placeholder="终端"
            >
              <el-option
                v-for="item in zhong"
                :key="item.value"
                :value="item.value"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 状态 -->
          <div class="tai">
            <el-select
              class="input-unit"
              v-model="tai"
              @change="search2"
              placeholder="状态"
            >
              <el-option
                v-for="item in state"
                :key="item.value"
                :value="item.value"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </div>
          <div class="tai" v-if="hasOp">
            <el-select
              class="input-unit"
              v-model="techop"
              @change="search3"
              placeholder="技师类型"
            >
              <el-option
                v-for="item in oplist"
                :key="item.value"
                :value="item.value"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <!-- 右边 -->
        <div class="screen" @click="arr" v-if="!getlook">
          <img class="screen-img" src="../assets/img/ding/tianjia.png" alt="" />
          <p class="screen-p">添加</p>
        </div>
        <div class="export" @click="export1" v-if="canExport && !getlook">
          <img class="export-img" src="../assets/img/ding/daochu.png" alt="" />
          <p class="export-p">导出</p>
        </div>
        <div class="export">
          <el-checkbox-button
            v-model="checked1"
            :label="recycle"
            size="large"
            @change="state2"
          />
        </div>
        <!-- <div class="take">
          <img
            class="take-img"
            src="../assets/img/slices5/list-icon-tool.png"
            alt=""
          />
          <p class="take-p">接单工具</p>
        </div> -->
      </div>
      <add :isAdd="addMine" @carsx="sx" :ahasOp="hasOp"></add>
      <div class="main">
        <carmanage
          :isState="isState"
          :iscarsx="sxx"
          :vkeyword="vkeywords"
          :vtechid="techids"
          :vtechduan="duans"
          :vtechtai="tais"
          :vtechop="techops"
          :vhasOp="hasOp"
        ></carmanage>
      </div>
    </div>
  </div>
</template>

<script>
import carmanage from "../components/carmanage.vue";
import add from "../components/add.vue";
import * as api from "../api/car.js";
import * as api1 from "../api/index.js";
import * as api2 from "../api/order.js";
export default {
  components: { carmanage, add },
  data() {
    return {
      addMine: 0,
      sxx: 0,
      keyword: "",
      duan: "",
      duans: "",
      tai: "",
      tais: "",
      name: "",
      vkeywords: "",
      belongCid: "",
      allList: [],
      techid: "",
      techids: "",
      zhong: [
        {
          value: 0,
          name: "全部",
        },
        {
          value: 1,
          name: "未绑定",
        },
        {
          value: 2,
          name: "已绑定",
        },
      ],
      state: [
        {
          value: 0,
          name: "全部",
        },
        {
          value: 1,
          name: "停止接单",
        },
        {
          value: 2,
          name: "等待接单",
        },
        {
          value: 3,
          name: "已接单",
        },
        {
          value: 4,
          name: "尚未登录",
        },
      ],
      oplist: [
        {
          value: 0,
          name: "全部",
        },
        {
          value: 1,
          name: "普通技师",
        },
        {
          value: 2,
          name: "外协技师",
        },
     
      ],
      datas: {
        perpage: "",
        keyword: "",
      },
      canExport: false,
      recycle: "回收站",
      checked1: false,
      isState: 2,
      techop:'',
      techops:'',
      hasOp:false,
    };
  },
  created() {
    this.changebelongCid();
  },
  methods: {
    arr() {
      this.addMine++;
      if (this.addMine == 3) {
        this.addMine = 1;
      }
    },
    search() {
      this.techids = this.techid;
    },
    state2(e) {
      if (e) {
        this.isState = 1;
      } else {
        this.isState = 2;
      }
    },
    search1() {
      this.duans = this.duan;
    },
    search2() {
      this.tais = this.tai;
    },
    search3() {
      this.techops = this.techop;
      console.log(this.techops);
    },
    sx() {
      this.sxx++;
    },

    async changebelongCid() {
      let res_carList = await api1.gettechlist(1);
      this.allList = res_carList.data;
      this.canExport = res_carList.carExport;
      this.hasOp = res_carList.hasOp;
    },
    export1() {
      // let newdata = {
      //   uid:this.techid,
      //   zhongduan:this.duan,
      //   tai:this.tai
      // }
      // let res = await api2.TechListExport(newdata);

      this.$msgbox({
        title: "消息",
        message: "是否确定导出",
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "导出中...";
            setTimeout(async () => {
              let newdata = {
                uid: this.techid,
                zhongduan: this.duan,
                tai: this.tai,
              };
              let res = await api2.TechListExport(newdata);
              console.log("打印结果", res);
              done();
              if (res.status == 200) {
                instance.confirmButtonLoading = false;
              }
            });
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success("导出成功请保存");
        })
        .catch(() => {
          this.$message.warning("取消导出");
        });
    },

    shai(val) {
      console.log("筛选----", val);
      this.keyword = val;
      this.vkeywords = this.keyword;
    },
  },
  computed: {
    getlook() {
      return this.$store.state.lookonly;
    },

  },
};
</script>

<style lang="less" scoped>
.mt-4 {
  float: left;
  // width: 300px;
  height: 40px;
  // margin-left: 60px;
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.box {
  width: 1730px;
  height: 840px;
  margin: 0;
  margin-left: 193px;
  margin-top: 0px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #f8fafc;
}
.nav-box {
  width: 1670px;
  height: 120px;
  background-color: #fff;
  overflow: hidden;
  padding-left: 30px;
  box-sizing: border-box;
}
.left {
  float: left;
  width: 300px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  margin-left: 30px;
  margin-top: 30px;
  margin-right: 850px;
  border-radius: 2px;
  position: relative;
}
span {
  float: left;
  width: 42px;
  height: 19px;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-right: 16px;
  color: #2a3346;
  opacity: 1;
}
.jian {
  float: left;
  transform: rotate(90deg);
  margin-right: 17px;
  margin-top: 10px;
}
.xian {
  float: left;
  width: 0px;
  height: 14px;
  margin-top: 14px;
  margin-right: 14px;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.screen {
  float: right;
  width: 130px;
  height: 50px;
  margin-top: 30px;
  margin-right: 60px;
  background: #2c68ff;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
}
.flush {
  float: left;
  width: 130px;
  height: 50px;
  margin-left: 30px;
  margin-top: 30px;
  border: 1px solid #2c68ff;
  opacity: 1;
  border-radius: 2px;
}
.export {
  float: right;
  width: 130px;
  height: 50px;
  margin-top: 30px;
  margin-right: 30px;
  border: 1px solid #2c68ff;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
}
.export /deep/ .el-checkbox-button__inner {
  width: 130px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}
.export /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #2c68ff;
  box-shadow: none !important;
  border-radius: 0;
}
.screen-img {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-left: 27px;
  margin-top: 14px;
}
.screen-p {
  float: left;
  margin-top: 27px;
  // width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #ffffff;
  opacity: 1;
}
.export-img,
.flush-img {
  float: left;
  width: 26px;
  height: 26px;
  margin-right: 16px;
  margin-left: 27px;
  margin-top: 12px;
}
.export-p,
.flush-p {
  float: left;
  width: 32px;
  height: 22px;
  font-size: 16px;
  margin-top: 25px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #2c68ff;
  opacity: 1;
}
input {
  float: left;
  width: 70px;
  height: 19px;
  font-size: 14px;
  margin-top: 10px;
  border: none;
  outline: none;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #9ea3b9;
  opacity: 1;
}
.jing {
  float: left;
  width: 40px;
  height: 40px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: -1px;
  right: -1px;
}
.icon {
  width: 14px;
  height: 14px;
  margin-left: 13px;
  margin-top: 13px;
  //   border: 2px solrgb(27, 28, 29)e96;
  border-radius: 50%;
  opacity: 1;
}
.main {
  width: 1670px;
  height: 660px;
  padding-left: 30px;
  box-sizing: border-box;
  background-color: #fff;
}

.mt-4 /deep/ .el-select .el-input {
  width: 200px;
}
.zhong /deep/ .el-select .el-input {
  width: 100px;
  margin: 0 10px;
}
.tai /deep/ .el-select .el-input {
  width: 100px;
  margin: 0 10px;
}
</style>
