<template>
  <div>
    <div class="box" v-show="vokes">
      <img class="cha" @click="close" src="../assets/img/ding/icon-close.png" alt=""/>
      <div class="box-main">
        <div class="main-nav">
          <h1>帐号启用</h1>
        </div>
        <div class="accounts">
          <div class="xing">*</div>
          <label for="">帐号</label>
          <input class="accounts-input" type="text" v-model="detail.username"  readonly/>
        </div>
        <div class="verify">
          <div class="xing">*</div>
          <label for="">验证码</label>
          <input class="verify-input" type="text" placeholder="验证码" id="smscode"/>
          <el-button id="gain" :plain="true" @click="send(detail.username)">获取验证码</el-button>


        </div>
         <div class="btn">
          <el-button class="set" :plain="true" @click="commit(detail.uid)">保存</el-button>
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '../api/car.js';

let num = 60;
let timer = '';
export default {
    props:["isvoke","uid"],
    data() {
        return {
            num,
            timer,
            vokes:this.isvoke,
            detail:{}
        }
    },
    methods: {
        async send(mobile) {
          let res1 = await api.carsendMessage(mobile);

          if(res1.code == 1){
              gain.disabled = true;
              timer =setInterval(function(){
              if (num == 0) {
                  clearInterval(timer);
                  gain.innerHTML = '重新发送';
                  gain.disabled = false;
                  num = 60;
              }else{
                  num--;
                  gain.innerHTML = num +'秒';
              } 
              },1000)
          }else{
            // alert(res1.message);
            this.$message.warning(res1.message)
          }
        },
        close() {
          this.vokes = false;
          clearInterval(timer);
          gain.innerHTML = '获取验证码';
          gain.disabled = false;
          num = 60;
          // this.voke = !this.voke;
        },
        async commit(uid){
            let code = smscode.value;
            if(code.length != 6){
              this.$message.warning('请输入正确的验证码')
              return false;
            }
              
            if(isNaN(code)){
              this.$message.warning('请输入正确的验证码')
              return false;
            }

            let res2 = await api.carEnable(uid,code);     

            if(res2.code == 1)
            {
              this.$message.success('成功启用')
              this.$emit('carsx',1);
              this.close();
            }else{
              // alert(res2.message);
              this.$message.warning(res2.message)
            }
        }
    },
    watch:{
      isvoke(newval){
        console.log('---',newval);
        this.vokes = newval
      },
      async uid(newVal){
        let res = await api.carDetail(newVal);
        this.detail = res.data;
      }

    }
};
</script>

<style lang="less" scoped>
.box {
  width: 640px;
  height: 940px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 22;
  // background-color: pink;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 600px;
  height: 940px;
  background: #fff;
  margin-left: 40px;
  margin-top: -21px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 160px;
  height: 26px;
  padding-top: 21px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.accounts,
.verify {
  // width: 435px;
  height: 40px;
  margin-bottom: 12px;
//   background-color: pink;
}
.accounts{
    margin-top: 30px;
}
.verify{
    margin-left: 33px;
}
.xing {
  float: left;
  width: 6px;
  height: 19px;
  font-size: 14px;
  margin-top: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #ff505a;
  opacity: 1;
}
label {
  width: 28px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
  color: #2a3346;
  opacity: 1;
}
.accounts-input {
  width: 300px;
  height: 32px;
  margin-left: 48px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
  outline: none;
}
.accounts-input:hover{
  border: 1px solid #c0c4cc;
}
input[type=text]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
.verify-input {
  width: 190px;
  height: 32px;
  margin-left: 34px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
  outline: none;
}
.verify-input:hover{
  border: 1px solid #c0c4cc;
}
#gain {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  color: #2A3346;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 118px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;
}
</style>
