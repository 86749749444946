<template>
  <div>
    <div class="box" v-show="miner">
      <img @click="close" src="../assets/img/ding/icon-close.png" alt="" />
      <div class="box-main">
        <div class="main-nav">
          <h1>帐号添加</h1>
        </div>
        <!-- 账号 -->
        <div class="accounts">
          <div class="xing">*</div>
          <label class="accounts-label" for="">帐号&#12288;&#12288;</label>
          <input
            class="accounts-input"
            type="text"
            oninput="value=value.replace(/[^\d]/g,'')"
            maxlength="11"
            placeholder="账号必须是手机号"
            v-model="username"
          />
        </div>
        <div class="plate" v-if="isSms">
          <div class="xing">*</div>
          <label class="accounts-label">验证码</label>
          <input
            class="accounts-input"
            type="text"
            style="margin-let: 20px; width: 133px"
            placeholder="验证码"
            v-model="smscode"
          />
          <el-button id="gain4" :plain="true" @click="send(username)"
            >获取验证码</el-button
          >
        </div>
        <!-- 联系人 -->
        <div class="plate">
          <div class="xing">*</div>
          <label class="accounts-label" for="">联系人&#12288;</label>
          <input
            class="accounts-input"
            type="text"
            v-model="name"
            placeholder="车主姓名"
            id="newname"
          />
        </div>
        <!-- 私人号码 -->
        <div class="plate">
          <div class="xing">*</div>
          <label class="accounts-label" for="">私人号码</label>
          <input
            class="accounts-input"
            type="text"
            oninput="value=value.replace(/[^\d]/g,'')"
            maxlength="11"
            v-model="tel1"
            placeholder="请输入私人号码"
            id="newtel1"
          />
        </div>
        <!-- 紧急号码 -->
        <div class="plate">
          <div class="xing">*</div>
          <label class="accounts-label" for="">紧急号码</label>
          <input
            class="accounts-input"
            type="text"
            oninput="value=value.replace(/[^\d]/g,'')"
            maxlength="11"
            v-model="tel2"
            placeholder="请输入紧急号码"
            id="newtel2"
          />
        </div>
        <!-- <div class="verify">
          <div class="xing">*</div>
          <label class="accounts-label" for="">联系方式</label>
          <input class="verify-name" type="text" v-model="name" placeholder="姓名" id="newname" />
          <input class="verify-phone" type="text" v-model="tel" placeholder="手机号" id="newtel" />
        </div> -->

        <!-- 车牌号 -->
        <div class="plate">
          <div class="xing">*</div>
          <label class="accounts-label" for="">车牌号&#12288;</label>
          <input
            class="plate-input"
            type="text"
            v-model="carNumber"
            id="newcarNumber"
            placeholder="请输入车牌号"
          />
        </div>
        <!-- 服务车型 -->
        <div class="plate">
          <div class="xing">*</div>
          <label class="accounts-label" for="">服务车型</label>
          <el-select
            v-model="carstyle"
            placeholder="选择车型"
            @change="changecarstyle"
          >
            <el-option
              v-for="item in styleList"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 接单工具 -->
        <div class="cartype">
          <div class="xing"></div>
          <label class="accounts-label" for="" style="margin-right: 21px"
            >接单工具</label
          >
          <el-radio v-model="type" label="1" size="large" @change="terminal"
            >APP</el-radio
          >
          <el-radio v-model="type" label="2" size="large" @change="terminal"
            >小程序</el-radio
          >
        </div>
          <!-- 接单工具 -->
        <div class="cartype" v-if="ahasOp">
          <div class="xing"></div>
          <label class="accounts-label" for="" style="margin-right: 21px"
            >技师类型</label
          >
          <el-radio v-model="is_op" label="0" size="large"
            >普通技师</el-radio
          >
          <el-radio v-model="is_op" label="1" size="large"
            >外协技师</el-radio
          >
        </div>
        <!-- 服务项目 -->
        <div class="item">
          <div class="xing">*</div>
          <label class="accounts-label" for="">服务项目</label>
          <div class="item-main">
            <el-checkbox-group
              v-model="checkboxGroup1"
              @change="checkboxs"
              size="large"
            >
              <el-checkbox-button
                v-for="city in cities"
                :key="city.id"
                :label="city.id"
              >
                {{ city.name }}
              </el-checkbox-button>
            </el-checkbox-group>
          </div>
        </div>
        <!-- 终端 -->
        <div class="terminal" v-if="false">
          <div class="xing"></div>
          <label class="terminal-label zhong" for=""
            >终端&#12288;&#12288;</label
          >
          <button class="bang">点击绑定</button>
        </div>
        <div class="btn">
          <input
            class="plate-input"
            type="hidden"
            v-model="type"
            id="newtype"
          />
          <button class="set" @click="commit">保存</button>
          <button class="cancel" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import * as api from "../api/car.js";
let num4 = 60;
let timer4;
// const checkboxGroup1 = ref([""]);
export default {
  props: ["isAdd", "uid","ahasOp"],
  data() {
    return {
      tag: false,
      cities: [
        {
          id: 0,
          name: "拖车",
        },
        {
          id: 1,
          name: "搭电",
        },
        {
          id: 2,
          name: "换胎",
        },
        {
          id: 3,
          name: "送油",
        },
        {
          id: 4,
          name: "充气",
        },
        {
          id: 5,
          name: "快修",
        },
        {
          id: 6,
          name: "开锁",
        },
        {
          id: 7,
          name: "吊车",
        },
        {
          id: 8,
          name: "困境",
        },
        {
          id: 9,
          name: "地库",
        },
      ],
      addList: [],
      miner: this.isAdd,
      checkboxGroup1: [],
      rescueType: "",
      username: "",
      name: "",
      mobile: "",
      tel: "",
      tel1: "",
      tel2: "",
      type: "1",
      is_op:'0',
      carstyle: "",
      showstyle: false,
      styleList: [],
      carNumber: "",
      isSms: false,
      smscode: "",
      num4,
      timer4,
    };
  },
  async create() {},
  methods: {
    async send(mobile) {
      let res1 = await api.carsendMessage(mobile);

      if (res1.code == 1) {
        gain4.disabled = true;
        timer4 = setInterval(function () {
          if (num4 == 0) {
            clearInterval(timer4);
            gain4.innerHTML = "重新发送";
            gain4.disabled = false;
            num4 = 60;
          } else {
            num4--;
            gain4.innerHTML = num4 + "秒";
          }
        }, 1000);
      } else {
        // alert(res1.message);
        this.$message.warning(res1.message);
      }
    },
    close() {
      this.miner = false;
      if (this.isSms == true) {
        gain4.innerHTML = "获取验证码";
        gain4.disabled = false;
      }
      clearInterval(timer4);
      num4 = 60;
    },
    checkboxs(e) {
      this.showstyle = this.checkboxGroup1.includes("1");
    },

    terminal(label) {
      this.tag = label == "1" ? true : false;
    },
    async commit() {
      console.log("点击保存", this.checkboxGroup1);
      if (this.username == "") {
        this.$message.warning("请输入帐号");
        return;
      }
      if (this.name == "") {
        this.$message.warning("请输入姓名");
        return;
      }
      if (this.carNumber == "") {
        this.$message.warning("请输入车牌号");
        return;
      }
      if (this.carstyle == "") {
        this.$message.warning("请选择服务车型");
        return;
      }
      if (this.checkboxGroup1.length == 0) {
        this.$message.warning("请选服务项目");
        return;
      }
      if (this.tel == "") {
        this.tel = this.username;
      } else if (/^[0-9][0-9- ]{6,10}[0-9]$/.test(this.tel) !== true) {
        this.$message.warning("请输入正确的手机号码");
        return;
      }

      let post_carstyle = this.carstyle;

      this.checkboxGroup1.forEach((item, index) => {
        if (index == 0) {
          this.rescueType = item;
        } else {
          this.rescueType = this.rescueType + "," + item;
        }
      });

      let data = {
        username: this.username,
        name: this.name,
        type: this.type,
        carNumber: this.carNumber,
        rescueType: this.rescueType,
        carstyle: post_carstyle,
        tel1: this.tel1,
        tel2: this.tel2,
        smsCode: this.smscode,
        is_op:this.is_op,
      };

      let res = await api.driverAdd(data);
      if (res.code == 1) {
        this.$message.success("成功");
        this.$emit("carsx", 1);
        Object.assign(this.$data, this.$options.data());
      } else if (res.code == 0) {
        this.$message.warning(res.message);
      } else if (res.code == 2) {
        this.isSms = true;
        this.$message.warning(res.message);
      }
    },
  },
  watch: {
    async isAdd(newval) {
      console.log("---", newval);
      this.miner = newval;
      let res = await api.getRescueList();
      this.cities = res.data.rescueTypeList;

      let res1 = await api.getStyleList();
      this.styleList = res1.data.styleList;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 640px;
  height: 940px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 22;
  // background-color: pink;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 600px;
  height: 940px;
  background: #fff;
  margin-left: 40px;
  margin-top: -21px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 160px;
  height: 26px;
  padding-top: 21px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.accounts,
.verify,
.plate,
.cartype,
.terminal {
  width: 430px;
  height: 40px;
  margin-bottom: 12px;
  display: flex;
  //   background-color: pink;
}
.item {
  display: flex;
  margin-left: 41px;
}
.item-label {
  float: left;
  height: 19px;
  font-size: 14px;
  margin-right: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
  color: #2a3346;
  opacity: 1;
}
.item-main {
  width: 240px;
  margin-left: -5px;
}
.el-checkbox-button {
  width: 64px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  margin: 0 5px 5px;
}
.accounts {
  margin-top: 30px;
  margin-left: 41px;
}
.verify {
  margin-left: 41px;
}
.plate {
  margin-left: 41px;
}
.cartype {
  margin-left: 41px;
}
.terminal {
  margin-top: 10px;
  margin-left: 41px;
  display: flex;
}
.xing {
  float: left;
  width: 6px;
  height: 19px;
  font-size: 14px;
  margin-top: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #ff505a;
  opacity: 1;
}
.accounts-label,
.verify-label,
.plate-label,
.plate1-label,
.terminal-label {
  width: 70px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
  color: #2a3346;
  opacity: 1;
  margin-right: 10px;
}

button {
  width: 289px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 4px;
}
.accounts-input,
.plate-input {
  width: 275px;
  height: 32px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  border-radius: 4px;
}
.accounts-input:hover {
  border: 1px solid #c0c4cc;
}
.plate-input:hover {
  border: 1px solid #c0c4cc;
}
.verify-name {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 4px;
  padding-left: 12px;
  outline: none;
}
.verify-phone {
  width: 150px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  opacity: 1;
  margin-left: 10px;
  padding-left: 12px;
  border-radius: 4px;
  outline: none;
}
.verify-name:hover {
  border: 1px solid #c0c4cc;
}
.verify-phone:hover {
  border: 1px solid #c0c4cc;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 127px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  vertical-align: top;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}
.item-main /deep/ .el-checkbox-button {
  border: 0;
  border-radius: 2px;
}
.item-main /deep/ .el-checkbox-button--large .el-checkbox-button__inner {
  border-left: 1px solid #dcdfe6;
  border-radius: 2px;
}
input[type="text"]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
#gain4 {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  color: #2a3346;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}
// .el-checkbox-group{
//   // margin-left: 10px;
// }
</style>
