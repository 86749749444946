import http from "../util/http.js";

export const userList = (data) => {
  let { page, perpage, keyword,status } = data;
  return http
    .get("/user/list", {
      params: {
        page,
        perpage,
        keyword,
        status
      },
    })
    .then((res) => res.data);
};

export const userDetail = (uid) => {
  return http
    .get("/user/detail", {
      params: {
        uid,
      },
    })
    .then((res) => res.data);
};

export const userDodisable = (uid) => {
  return http
    .post("/user/do-disable", {
      uid: uid,
    })
    .then((res) => res.data);
};

export const userDoEnable = (uid, code) => {
  return http
    .post("/user/do-enable", {
      uid: uid,
      smsCode: code,
    })
    .then((res) => res.data);
};

export const userDoEdit = (
  uid,
  password,
  password_repeat,
  name,
  tel,
  roles
) => {
  return http
    .post("/user/do-edit", {
      uid: uid,
      password: password,
      password_repeat: password_repeat,
      name: name,
      tel: tel,
      role: roles,
    })
    .then((res) => res.data);
};

export const usersendMessage = (mobile) => {
  return http
    .post("/site/send-message", {
      mobile: mobile,
      type: 3,
    })
    .then((res) => res.data);
};

export const userAdd = (data) => {
  let { username, name, tel, role, password, password_repeat, smsCode } = data;
  return http
    .post("/user/do-add", {
      username,
      name,
      tel,
      role,
      password,
      password_repeat,
      smsCode,
    })
    .then((res) => res.data);
};

export const rolelist = (cid) => {
  return http
    .get("/service-provider/src-role", {
      params: {
        cid: cid,
      },
    })
    .then((res) => res.data);
};

export const myrolelist = () => {
  return http
    .get("/user/role-list", {
      params: {},
    })
    .then((res) => res.data);
};

export const userAddmore = (cid, username, name, tel, role, smsCode,password,password_repeat) => {
  return http
    .post("/user/do-add", {
      cid: cid,
      username: username,
      name: name,
      tel: tel,
      role: role,
      smsCode: smsCode,
      password:password,
      password_repeat:password_repeat
    })
    .then((res) => res.data);
};

export const oplog = (uid) => {
  return http
    .get("/user/op-log", {
      params: {
        uid: uid,
      },
    })
    .then((res) => res.data);
};
