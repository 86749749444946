<template>
  <div>
    <table border="0" cellspacing="0">
      <thead>
        <th class="num">帐号</th>
        <th class="phone">联系方式</th>
        <th class="phone">车辆信息</th>
        <th class="serve">服务项目</th>
        <th class="jiedan">接单工具</th>
        <th class="order">接单</th>
        <th class="weixin">微信</th>
        <th class="terminal">终端</th>
        <th class="terminal">状态</th>
        <th class="terminal" v-show="vhasOp">技师类型</th>
        <th class="terminal" v-show="vhasOp">聚合接单</th>
        <th class="addtime">添加时间</th>
        <th class="operate">操作</th>
      </thead>
      <tbody>
        <tr
          :class="
            (index % 2 == 0 && !changecolor) || (index % 2 !== 0 && changecolor)
              ? 'bg_color'
              : 'bg_color2'
          "
          v-for="(data, index) in carList"
          :key="index"
        >
          <td class="td-num">
            <i @click="look(data.uid)" class="xiangqing">{{ data.username }}</i>
          </td>
          <td class="td-phone">
            <span class="phone-span"
              >{{ data.name }}
              <br />
              {{ data.tel
              }}<button
                @click="call(data.tel, '')"
                class="call"
                v-show="getEmic != ''"
              ></button
            ></span>
          </td>
          <td class="td-phone">
            <strong>{{ data.carNumber }}</strong>
          </td>
          <td class="td-serve">{{ data.rescueType }}</td>
          <td class="td-tool">{{ data.type }} {{ data.appver }}</td>
          <td class="td-order">{{ data.num }}</td>
          <td :class="data.wxBind == '未绑定' ? 'weibang' : 'td-weixin'">
            {{ data.wxBind }}
          </td>
          <td :class="data.gpsBind == '未绑定' ? 'weibang' : 'td-terminal'">
            {{ data.gpsBind }}
          </td>
          <td class="td-terminal">
            <i
              :class="
                data.serviceStatus == '等待接单'
                  ? 'leisure1'
                  : data.serviceStatus == '停止接单'
                  ? 'leisure1'
                  : data.serviceStatus == '尚未登录'
                  ? 'leisure2'
                  : data.serviceStatus == '已接单'
                  ? 'leisure3'
                  : ''
              "
              >{{ data.serviceStatus }}</i
            >
          </td>
          <td class="td-order" v-show="vhasOp">
            <i class="td-weixin" v-if="data.isOp == 1" style="cursor: pointer; color:red;" @click="dddd(data.uid)">外协</i>
            <i class="weibang" v-else >内部</i>
          </td>
          <td class="td-order" v-show="vhasOp">
            <i class="td-weixin" v-if="data.is_black == 1" style="cursor: pointer; color:red;" @click="ddxx(data.uid,0)">关闭</i>
            <i class="weibang" v-else style="cursor: pointer; color:green;" @click="ddxx(data.uid,1)">开启</i>
          </td>
          <td class="td-addtime">{{ data.createTime }}</td>
          <td class="td-operate">
            <i
              class="know"
              v-if="data.selfReceived == 1 && getlook"
              >自动接单</i
            >
            <i
              class="know"
              v-if="data.selfReceived == 0 && getlook"
              >调度指派</i
            >
            <i
              class="know"
              v-if="data.selfReceived == 1 && !getlook"
              @click="auto(data.uid, 0)"
              >自动接单</i
            >
            <i
              class="know"
              v-if="data.selfReceived == 0 && !getlook"
              @click="auto(data.uid, 1)"
              >调度指派</i
            >
            <i class="tiao"></i>
            <i
              class="visit1"
              v-if="data.status == 1 && getlook"
             
              >已启用</i
            >
            <i class="visit2" v-if="data.status == 0 && getlook"
              >已禁用</i
            >
            <i
              class="visit1"
              v-if="data.status == 1 && !getlook"
              @click="Dodisable(data.uid)"
              >已启用</i
            >
            <i class="visit2" v-if="data.status == 0 && !getlook" @click="visit(data.uid)"
              >已禁用</i
            >
            <i class="tiao"></i>

            <router-link
              :to="`/Maps?uid=${data.uid}`"
              class="know"
              @click="dingwei(data.uid)"
            >
              定位
            </router-link>
            <!-- <i class="complain">权限</i>
            <i class="tiao"></i>
            <i class="log">日志</i> -->
          </td>
        </tr>
      </tbody>

      <!-- 分页器 -->
      <div class="jinXing-sorter">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="datas.perpage"
          @prev-click="prev_view"
          @next-click="next_view"
          @current-change="current_view"
        >
        </el-pagination>
      </div>
    </table>
    <opriceself :isopsets="opss" :opuid="selfuid"  @opclose="opcloses"></opriceself>
    <invoke :isvoke="voke" :uid="uid" @carsx="sx" ></invoke>
    <examine :ismine="mine" :uid="uid" @carsx="sx1" :hasOp="vhasOp"></examine>

  </div>
</template>

<script>
import Examine from "./examine.vue";
import invoke from "./invoke.vue";
import opriceself from "./opriceself.vue";
import * as ola from "../api/olawebsock.js";

import * as api from "../api/car.js";
import { userDodisable } from "../api/user";
import { ElMessageBox } from "element-plus";
export default {
  props: ["iscarsx", "vkeyword", "vtechid", "vtechduan", "vtechtai", "isState","vtechop","vhasOp"],
  components: { invoke, Examine,opriceself },
  data() {
    return {
      voke: 0,
      mine: 0,
      carList: [],
      uid: 0,
      pagesize: 1,
      total: 100,
      datas: {
        page: 1,
        perpage: 7,
        keyword: "",
        uid: "",
        zhongduan: "",
        tai: "",
        status: 2,
        ops:'',
      },
      emicreg: "",
      changecolor:true,
      opss:false,
      selfuid:''
    };
  },

  async created() {
    this.res_carList(this.datas);
  },

  methods: {
    async res_carList(data) {
      let res_carList = await api.carList(data);
      this.carList = res_carList.data;
      this.total = parseInt(res_carList.total);
    },
    sx() {
      this.res_carList(this.datas);
    },
    sx1() {
      this.res_carList(this.datas);
    },
    dddd(uid)
    {
      this.opss = true;
      this.selfuid = uid;
    },
    async ddxx(uid,e)
    {
      if(e == 0)
      {
        ElMessageBox.confirm("是否确定开启聚合接单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await api.setBlack(uid, e);
            if (res.code == 1) {
              this.res_carList(this.datas);
            } else {
              this.$message.warning(res.message);
            }
          })
          .catch(() => {
            this.$message.info("取消操作");
          });
      }else{
        ElMessageBox.confirm("是否确定关闭聚合接单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await api.setBlack(uid, e);
            if (res.code == 1) {
              this.res_carList(this.datas);
            } else {
              this.$message.warning(res.message);
            }
          })
          .catch(() => {
            this.$message.info("取消操作");
          });
      }
    },
    opcloses()
    {
      this.opss = false;
    },
    visit(uid) {
      this.voke++;
      if (this.voke == 3) {
        this.voke = 1;
      }
      this.uid = uid;
    },
    look(uid) {
      this.mine++;
      if (this.mine == 3) {
        this.mine = 1;
      }
      this.uid = uid;
    },

    dingwei(val) {
      this.$store.state.stype = val;
    },

    async auto(uid, type) {
      if (type == 0) {
        ElMessageBox.confirm("是否确定开启调度接单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await api.carAuto(uid, type);
            if (res.code == 1) {
              this.res_carList(this.datas);
            } else {
              this.$message.warning(res.message);
            }
          })
          .catch(() => {
            this.$message.info("取消操作");
          });
      } else {
        ElMessageBox.confirm("是否确定开启自动接单", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await api.carAuto(uid, type);
            if (res.code == 1) {
              this.res_carList(this.datas);
            } else {
              this.$message.warning(res.message);
            }
          })
          .catch(() => {
            this.$message.info("取消操作");
          });
      }
    },
    async Dodisable(uid) {
      ElMessageBox.confirm("是否确定禁用该账号", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await api.carDisable(uid);
          if (res.code == 1) {
            this.res_carList(this.datas);
          } else {
            his.$message.warning(res.message);
          }
        })
        .catch(() => {
          this.$message.info("取消操作");
        });
    },
    // 分页器
    prev_view() {
      console.log("---车辆上一页", this.datas.page);
    },
    // 分页器
    next_view() {
      console.log("---车辆下一页", this.datas.page);
    },
    // 点击页数时触发
    current_view(e) {
      this.datas.page = e;
      this.res_carList(this.datas);
    },
    call(tel, str) {
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getlook()
    {
      return this.$store.state.lookonly;
    }
  },
  watch: {
    iscarsx(newval) {
      this.res_carList(this.datas);
    },
    vkeyword(newval) {
      this.datas.keyword = newval;
      this.res_carList(this.datas);
    },
    vtechid(newval) {
      this.datas.uid = newval;
      this.res_carList(this.datas);
    },
    vtechduan(newval) {
      this.datas.zhongduan = newval;
      this.res_carList(this.datas);
    },
    vtechtai(newval) {
      this.datas.tai = newval;
      this.res_carList(this.datas);
    },
    vtechop(newval) {
      this.datas.ops = newval;
      //console.log('技师类型',newval)
      this.res_carList(this.datas);
    },
    isState(newval) {
      this.datas.status = newval;
      this.res_carList(this.datas);
    },
  },
};
</script>

<style lang="less" scoped>
thead {
  text-align: left;
  // height: 50px !important;
  background-color: #f5f5f5;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  background-color: #ffffff !important;
}

.bg_color2 {
  background-color: #fafafa !important;
}

.num,
.phone,
.serve,
.jiedan,
.order,
.weixin,
.terminal,
.addtime,
.operate {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  padding-top: 15px;
  padding-bottom: 15px;
}
.num {
  width: 128px;
  height: 19px;
  padding-left: 30px;
}
.phone {
  width: 150px;
  height: 19px;
}
.serve {
  width: 220px;
  height: 19px;
}
.jiedan {
  width: 150px;
  height: 19px;
}
.order,
.weixin,
.terminal {
  width: 90px;
  height: 19px;
}
.addtime {
  width: 170px;
  height: 19px;
}
.operate {
  width: 270px;
  height: 19px;
}
td {
  height: 70px !important;
  box-sizing: border-box;
}

.td-num,
.td-serve,
.td-tool,
.td-order,
.td-weixin,
.td-terminal,
.td-addtime {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  // line-height: 32px;
  color: #727e96;
  opacity: 1;
}
.td-num {
  width: 90px;
  padding-left: 30px;
}
strong {
  display: inline-block;
  height: 10px;
  font-weight: 400;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  opacity: 1;
}
.phone-span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  // line-height: 32px;
  color: #727e96;
  opacity: 1;
  display: inline-block;
  margin-top: -5px;
  height: 10px;
}
.td-serve {
  // display: inline-block;
  margin-right: 20px;
  // padding-top: 17px;
  display: flex;
  align-items: center;
}
.td-tool {
  width: 58px;
}
.td-order {
  width: 25px;
}
.td-weixin {
  width: 42px;
}
.td-terminal {
  width: 76px;
}
i {
  font-style: normal;
}
.leisure1 {
  color: #27cc8b;
}
.leisure2 {
  color: #ccc;
}
.leisure3 {
  color: #2c68ff;
}
.td-addtime {
  width: 138px;
}
.tiao {
  display: inline-block;
  width: 0px;
  height: 14px;
  margin: 0 6px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.check,
.know,
.visit1,
.complain,
.log {
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #2c68ff;
  opacity: 1;
  cursor: pointer;
}
.check {
  width: 32px;
}
.know {
  width: 64px;
}
.visit1 {
  width: 48px;
  cursor: pointer;
}
.visit2 {
  width: 48px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #ff505a;
  opacity: 1;
}
.complain {
  width: 32px;
}
.log {
  width: 32px;
}

.jinXing-sorter {
  position: absolute;
  top: 800px;
  right: 30px;
  padding: 30px;
}

.weibang {
  color: #ccc;
  height: 19px;
  font-size: 14px;
}

.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}
i,
.xiangqing {
  font-style: normal;
  color: #2c68ff;
}
.xiangqing {
  cursor: pointer;
}
</style>
